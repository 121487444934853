import { lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";

import CheckoutFailed from "web/Pages/Checkout/Failed";
import CheckoutTourismPending from "web/Pages/Checkout/Pending/DomesticTourism";
import CheckoutSplash from "web/Pages/Checkout/Splash";
import SubscriptionSuccess from "web/Pages/Checkout/Subscription/Success";
import CheckoutSuccess from "web/Pages/Checkout/Success";
import ContactForm from "web/Pages/ContactForm";
import OrderPrepaidCardSuccess from "web/Pages/Customer/Content/PrepaidCards/OrderPrepaidCardSuccess";
import ProductPrepaidCard from "web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard";
import HelpCenter from "web/Pages/HelpCenter";
import NativeAppLogin from "web/Pages/NativeAppLogin";
import Page404 from "web/Pages/Page404";
import ProductsQuickList from "web/Pages/ProductsQuickList";
import Search from "web/Pages/Search";
import SubscriptionsGroups from "web/Pages/Subscriptions/subscriptionsGroups";
import Tourism from "web/Pages/Tourism";

import Main from "web/Layout/Main";

import PrefetchAttributes from "web/Components/Common/PrefetchAttributes";
import TokenRefresh from "web/Components/Common/TokenRefresh";

import useInternetConnection from "web/hooks/useInternetConnection/useInternetConnection";

import getToken from "web/utils/system/essentials/getToken";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import { CompanyEvents } from "web/External/company-events";
import { Proposals } from "web/External/proposals";
import { TotalReward } from "web/External/total-reward";
import { useGoogleContext } from "web/context/google.context";
import {
  OutcomingWebViewMessageType,
  sendMessageToNativeApp,
} from "web/context/webView";
import { setTokenTtl } from "web/features/app/appSlice";
import { resetDetailsGotten } from "web/features/cart/cartSlice";
import { getCartDetails } from "web/features/cart/cartThunks";
import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import {
  useLazyGetEmployeeConfigurationQuery,
  useLazyGetEmployeeDetailsQuery,
} from "web/features/employee/employeeApiSlice";
import { getBanksForce } from "web/features/pointsBank/pointsBankApiSlice";
import useDataCachedCategoriesPrefetch from "web/features/useDataCached/useDataCachedCategoriesPrefetch";

import Intercom from "../../Components/Common/Intercom";
import useSession from "../../hooks/useSession/useSession";
import Loading from "../Common/Loading";
import Gtm from "../Gtm";
import Navigation from "../NavigationPanel/Navigation/navigation";
import NoInternetConnection from "../NoInternetConnection";
import RentableGroupWrapper from "../RentableGroup/RentableGroupCalculatorForm/RentableGroupWrapper";
import UrlResolver from "../UrlResolver";
import WindowDimension from "../WindowDimension";
import Reload from "./Reload";
import fetchSesssionData from "./fetchSessionData";
import isCheckoutDefault from "./utils/isCheckoutDefault";
import useCartRefreshHandler from "./utils/useCartRefreshHandler";

const Customer = lazy(() => import("web/Pages/Customer"));
const PageGenerator = lazy(() => import("web/Pages/PageGenerator"));
const Checkout = lazy(() => import("web/Pages/Checkout"));

const storage = new BrowserPersistence();

const UserRoutes = () => {
  const { loading, isLogged, error, ttl } = useSession();
  const dispatch = useDispatch();
  const { data: customerData } = useGetCustomerDetailsQuery(undefined, {
    skip: !isLogged,
  });
  const { id, impersonated } = customerData || {};
  const { isStoreConfigGotten, storeConfig } = useSelector(({ app }) => app);

  const { items } = useSelector(({ codes }) => codes);

  const companyConfiguration = useGetCompanyDetailsQuery(undefined, {
    skip: !isLogged,
  });
  const hasCompanyEvents = companyConfiguration?.data?.enableCompanyEvents;
  const hasProposals = companyConfiguration?.data?.enableProposals;
  const hasTotalRewardStatement =
    companyConfiguration?.data?.enableTotalRewardStatement;

  const { isDisconnectedWebView } = useInternetConnection();
  const { initGoogleServices } = useGoogleContext();

  const getDataOnPageVisibility = useCallback(async () => {
    if (document.visibilityState === "visible") {
      await getToken();

      if (!isCheckoutDefault()) {
        getBanksForce(dispatch);
        dispatch(resetDetailsGotten());
        dispatch(getCartDetails());
      }
    }
  }, []);

  const [
    triggerDetails,
    { data: employeeDetails, isLoading: isLoadingDetails },
  ] = useLazyGetEmployeeDetailsQuery();
  const { rentableGroupSelectionWindows } = employeeDetails || {};
  const [triggerConfig, { isLoading: isLoadingConfig, data: employeeConfig }] =
    useLazyGetEmployeeConfigurationQuery();
  const { rentableGroupCalculatorEnabled } = employeeConfig || {};

  useEffect(() => {
    if (isLogged && !loading) {
      triggerDetails();
      triggerConfig();
      dispatch(setTokenTtl(ttl as number));
      fetchSesssionData(dispatch);
      window.addEventListener("visibilitychange", getDataOnPageVisibility);
    }

    return () => {
      window.removeEventListener("visibilitychange", getDataOnPageVisibility);
    };
  }, [isLogged, loading]);

  useDataCachedCategoriesPrefetch({ isLogged, loading });

  useEffect(() => {
    const { google_api_key, google_api_enable, primary_color } =
      storeConfig || {};
    if (google_api_key && google_api_enable === "1") {
      initGoogleServices(google_api_key);
    }

    if (primary_color) {
      const root = document.documentElement;
      root.style.setProperty("--primary-color", primary_color);
    }
  }, [storeConfig]);

  useEffect(() => {
    if (!window.ReactNativeWebView || !id) return;
    const token = storage.getItem(storageNames.tokenAccess);
    sendMessageToNativeApp({
      type: OutcomingWebViewMessageType.LOGIN,
      payload: {
        userId: id.toString(),
        token,
        idToken: storage.getItem(storageNames.idToken),
      },
    });
  }, [storage, isLogged, id]);

  useCartRefreshHandler();

  const isLoading =
    !!loading ||
    !isStoreConfigGotten ||
    !storeConfig?.token ||
    !!isLoadingDetails ||
    !!isLoadingConfig;

  const defaultRoutes = (
    <Switch>
      <Route exact path={urls.home} component={PageGenerator} />
      <Route exact path={urls.notFound} component={Page404} />
      <Route path={urls.search} component={Search} />
      <Route path={urls.navigation} component={Navigation} />
      <Route path={urls.tourism} component={Tourism} />
      <Route path={urls.customer} component={Customer} />
      <Route path={urls.checkoutUUID} component={Checkout} />
      <Route path={urls.checkoutExternal} component={Checkout} />
      <Route path={urls.checkout} component={Checkout} />
      <Route
        path={urls.checkoutSubscriptionSuccess}
        component={SubscriptionSuccess}
      />
      <Route path={urls.checkoutSuccess} component={CheckoutSuccess} />
      <Route
        path={urls.checkoutTourismPending}
        component={CheckoutTourismPending}
      />
      <Route
        path={urls.checkoutSuccessPrepaidCard}
        component={OrderPrepaidCardSuccess}
      />
      <Route path={urls.checkoutFailed} component={CheckoutFailed} />
      <Route path={urls.checkoutSplash} component={CheckoutSplash} />
      <Route path={urls.productsQuickList} component={ProductsQuickList} />
      <Route path={urls.helpCenter} component={HelpCenter} />
      <Route path={urls.contactForm} component={ContactForm} />
      <Route path={urls.nativeAppLogin} component={NativeAppLogin} />
      <Route path={urls.orderPrepaidCard} component={ProductPrepaidCard} />
      {hasCompanyEvents && (
        <Route path={urls.companyEvents} component={CompanyEvents} />
      )}
      {hasProposals && <Route path={urls.proposals} component={Proposals} />}
      {hasTotalRewardStatement && (
        <Route path={urls.totalRewardStatement} component={TotalReward} />
      )}
      <Route path={urls.subscriptions} component={SubscriptionsGroups} />
      <Route
        render={(props) => <UrlResolver {...props} isLogged={isLogged} />}
      />
    </Switch>
  );

  const noInternetConnectionRoutes = (
    <Switch>
      <Route path={urls.productsQuickList} component={ProductsQuickList} />
      <Route component={NoInternetConnection} />
    </Switch>
  );

  const noInternetConnectionAndNoItems = (
    <Switch>
      <Route component={NoInternetConnection} />
    </Switch>
  );

  let routes;

  if (isDisconnectedWebView) {
    if (!items.length) routes = noInternetConnectionAndNoItems;
    else routes = noInternetConnectionRoutes;
  } else {
    routes = defaultRoutes;
  }

  if (error) return <Reload />;
  if (isLoading) return <Loading />;

  const content = (
    <>
      <Main>{routes}</Main>
      <TokenRefresh />
      <PrefetchAttributes />
      <WindowDimension />
      <Gtm />
      <Intercom />
    </>
  );

  if (rentableGroupSelectionWindows?.popup)
    return (
      <RentableGroupWrapper
        calculatorEnabled={rentableGroupCalculatorEnabled!}
        impersonated={impersonated!}
      >
        {content}
      </RentableGroupWrapper>
    );

  return content;
};

export default UserRoutes;
