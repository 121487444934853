import { FC } from "react";

import withLabel from "web/Components/Common/withLabel";

import classes from "./new.scss";

interface IProductCardTitleLabelNewProps {
  label: string;
}

const ProductCardTitleLabelNew: FC<IProductCardTitleLabelNewProps> = ({
  label = "",
}) => {
  return label ? (
    <span className={classes.root} data-t2={label} data-t1="title_label_new">
      {label}
    </span>
  ) : null;
};

export default withLabel(ProductCardTitleLabelNew);
