import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

import FAQ from "web/Pages/FAQ/faq";

import __ from "web/Layout/Translations";

import ArrowLeftIcon from "web/assets/icons/arrow-left.svg";

import storeConfigMin from "web/queries/default/storeConfigMin.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { getStoreConfig } from "web/features/app/appThunks";

import Loading from "../../Common/Loading";
import Logo from "../../Common/Logo";
import UrlResolver from "../../UrlResolver";
import classes from "./FAQRoutes.scss";

const FAQRoutes = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const {
    isStoreConfigGotten,
    storeConfig: { header_logo_src, header_logo_alt },
  } = useSelector((state) => state.app);

  const { isParamLangEn } = useLangFromParams();

  useEffect(() => {
    (async () => {
      await dispatch(
        getStoreConfig({ id: 1, query: storeConfigMin, omitToken: true })
      );
    })();
  }, [dispatch, getStoreConfig, storeConfigMin]);

  if (!isStoreConfigGotten) return <Loading />;

  return (
    <main className={classes.root}>
      <div className={classes.logoBox}>
        <Logo logoSrc={header_logo_src} logoAlt={header_logo_alt} />
      </div>
      <Link to="/" className={classes.returnLink}>
        <ArrowLeftIcon className={classes.returnIcon} />
        {isParamLangEn ? "Go back to login page" : __("Wróc do logowania")}
      </Link>
      <Switch>
        <Route exact path={url} component={FAQ} />
        <Route path={url} component={UrlResolver} />
      </Switch>
    </main>
  );
};

export default FAQRoutes;
