import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { FacetsStats } from "web/types/Product";

import Categories from "./Categories";
import Products from "./Products";
import classes from "./autocomplete.scss";

const PRODUCTS_LIST_LENGTH = 4;
const CATEGORIES_LIST_LENGTH = 10;

interface ISearchAutocompleteMobileProps {
  data: FacetsStats;
  searchPhrase: string;
  closeAction: () => void;
}

const SearchAutocompleteMobile: FC<ISearchAutocompleteMobileProps> = ({
  data = {} as FacetsStats,
  searchPhrase,
  closeAction,
}) => {
  const [productIds, categoriesIds] = useMemo(() => {
    const productsIdsMemo =
      data && data.items_ids && isArrayHasItems(data.items_ids)
        ? data.items_ids.slice(0, PRODUCTS_LIST_LENGTH).map((id) => +id)
        : [];
    const categories =
      data &&
      data.facets &&
      isArrayHasItems(data.facets) &&
      data.facets.find((facet) => facet.code === "category_ids");
    const categoriesIdsMemo =
      categories && isArrayHasItems(categories.values)
        ? categories.values
            ?.slice(0, CATEGORIES_LIST_LENGTH)
            .map((category) => +category.value)
        : [];
    return [productsIdsMemo, categoriesIdsMemo];
  }, [data]);

  return isArrayHasItems(productIds) || isArrayHasItems(categoriesIds) ? (
    <div className={classes.root} data-t1="autocomplete">
      <section className={classes.categories}>
        <Categories
          ids={categoriesIds}
          searchPhrase={searchPhrase}
          closeAction={closeAction}
        />
      </section>
      <section className={classes.products}>
        <Products ids={productIds} closeAction={closeAction} />
      </section>
    </div>
  ) : (
    <div className={classes.empty}>{__("Brak wyników wyszukiwania")}</div>
  );
};

export default SearchAutocompleteMobile;
