import { func, number, oneOfType, string } from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import __ from "web/Layout/Translations";

import classes from "./notification.scss";

const defaultCommunicates = {
  success: "Operacja wykonana poprawnie.",
  warning: "Operacja mogła nie zostać wykonana poprawnie.",
  error: "Błąd podczas wczytywania. Spróbuj jeszcze raz.",
};

const HIDE_TIMEOUT = 3000;
const Notification = ({ type, id, message, remove, timeout }) => {
  const dispatch = useDispatch();
  const setTimeoutIdRef = useRef(null);
  const removeAction = useCallback(() => {
    dispatch(remove(id));
  }, [id, remove]);

  useEffect(() => {
    clearTimeout(setTimeoutIdRef.current);
    setTimeoutIdRef.current = setTimeout(() => {
      dispatch(remove(id));
    }, timeout ?? HIDE_TIMEOUT);
    return () => {
      clearTimeout(setTimeoutIdRef.current);
    };
  }, [id, remove]);

  let classNameRoot;

  switch (type) {
    case "success":
      classNameRoot = classes.rootSuccess;
      break;
    case "warning":
      classNameRoot = classes.rootWarning;
      break;
    default:
      classNameRoot = classes.rootError;
      break;
  }

  let localMessage = message;
  if (!localMessage) {
    switch (type) {
      case "success":
        localMessage = defaultCommunicates.success;
        break;
      case "warning":
        localMessage = defaultCommunicates.warning;
        break;
      default:
        localMessage = defaultCommunicates.error;
        break;
    }
  }

  return (
    <div className={classes.container} data-t1="notification" data-t2={type}>
      <button
        className={classNameRoot}
        type="button"
        onClick={removeAction}
        data-t1="closeNotificationButton"
      >
        <figure className={classes.buttonCircle} />
        <span
          className={classes.buttonText}
          data-t1="notificationValue"
          data-t2={__(localMessage)}
        >
          {__(localMessage)}
        </span>
        <span className={classes.buttonClose}>✕</span>
      </button>
    </div>
  );
};

Notification.propTypes = {
  type: string.isRequired,
  id: oneOfType([string, number]).isRequired,
  message: string,
  remove: func.isRequired,
  timeout: number,
};

Notification.defaultProps = {
  message: "",
};

export default Notification;
