import { FC } from "react";

import Rating from "web/Components/Common/Rating";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import Bestseller from "./Bestseller";
import classes from "./reviewsLabel.scss";

interface IProductCardReviewLabel {
  average: number;
  reviews: number;
  ids: (number | string)[];
  type: string;
}

const ProductCardReviewsLabel: FC<IProductCardReviewLabel> = ({
  average = 0,
  reviews = 0,
  ids = [],
  type,
}) => {
  return [
    "international_tourism_travelplanet",
    "international_tourism_other",
    "abonament_group_1",
    "abonament_group_2",
  ].includes(type) ? null : (
    <div className={classes.container}>
      <div className={classes.root}>
        <Rating
          className={classes.reviews}
          average={average}
          reviews={reviews}
        />
        {isArrayHasItems(ids) && <Bestseller code="bestseller" ids={ids} />}
      </div>
    </div>
  );
};

export default ProductCardReviewsLabel;
