import { FC, SyntheticEvent } from "react";
import { Link } from "react-router-dom";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import New from "./New";
import classes from "./titleLabel.scss";

interface IProductCardTitleLabelProps {
  name: string;
  ids: (number | string)[];
  to: {
    pathname: string;
    search: string;
    state: { categoryId: string; clickFrom?: string };
  };
  onClick: (evt?: SyntheticEvent) => void;
  isDisabled?: boolean;
  additionalClassname?: string;
}

const ProductCardTitleLabel: FC<IProductCardTitleLabelProps> = ({
  name,
  ids,
  to,
  onClick,
  isDisabled = false,
  additionalClassname = "",
}) => {
  return (
    <div
      className={`${classes.root} ${
        isDisabled ? classes.disabled : ""
      } ${additionalClassname}`}
    >
      {isArrayHasItems(ids) && <New code="new" ids={ids} />}
      <h2 className={classes.title} data-t2={name} data-t1="title_label">
        <Link
          to={to}
          data-t1="title_label_link"
          title={name}
          data-t2={to}
          onClick={onClick}
        >
          {name}
        </Link>
      </h2>
    </div>
  );
};

export default ProductCardTitleLabel;
