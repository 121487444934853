import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

const Gtm = () => {
  const { gtm_api_key: gtmKey, gtm_api_enable: isEnabled } = useSelector(
    (state) => state.app.storeConfig
  );

  useEffect(() => {
    if (TagManager && isEnabled) {
      TagManager.initialize({
        gtmId: gtmKey,
      });
    }
  }, [TagManager, gtmKey, isEnabled]);

  return null;
};

export default Gtm;
