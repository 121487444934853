// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointsStatus-root-WwU{display:block;text-align:center;font-size:14px;font-weight:700}.pointsStatus-points-zR0{font-size:inherit}`, "",{"version":3,"sources":["webpack://./web/Layout/ProductCard/Default/Footer/PointsStatus/pointsStatus.scss"],"names":[],"mappings":"AAIuB,uBACrB,aAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CAGF,yBACE,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n  text-align: center;\n  font-size: 14px;\n  font-weight: 700;\n}\n\n.points {\n  font-size: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pointsStatus-root-WwU`,
	"points": `pointsStatus-points-zR0`
};
export default ___CSS_LOADER_EXPORT___;
