import { useLocation } from "react-router-dom";

import { useAppContext } from "web/context/app";

import NavigationPanel from "./navigationPanel";

const hiddenPanelMobilePathNames = ["/checkout/", "/checkout_failed/", "/company-events", "/checkout-external/prepaid-card"];

const NavigationPanelContainer = () => {
  const { pathname } = useLocation();
  const { isMobile } = useAppContext();

  if (
    isMobile &&
    !hiddenPanelMobilePathNames.some((path) => !pathname.indexOf(path))
  ) {
    return <NavigationPanel />;
  }

  return null;
};

export default NavigationPanelContainer;
