import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import Routes from "web/Layout/Routes";

import useProviderValue from "web/hooks/useProviderValue";

import setDomPurifyAttributes from "web/utils/setDomPurifyAttributes";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";

import { magentoUrlKey, msURlKey } from "web/constants/graphqlUrls";

import { AppProvider } from "web/context/app";
import { FeatureFlagsProvider } from "web/context/featureFlags";
import { GoogleProvider } from "web/context/google.context";

import MobileAppUpdateModal from "../MobileAppUpdateModal";

setDomPurifyAttributes();

type GraphqlAsGetCasted = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
) => Promise<Response>;

const magentoLink = new HttpLink({
  uri: magentoUrlKey,
  fetch: graphQlAsGet as GraphqlAsGetCasted,
});

const msLink = new HttpLink({
  uri: msURlKey,
  fetch: graphQlAsGet as GraphqlAsGetCasted,
});

const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "ms",
    msLink,
    magentoLink
  ),
  cache: new InMemoryCache(),
});

const featureFlags = {
  apiUrl: process.env.FEATURE_FLAGS_URL || "",
};

const App = () => {
  const providerValue = useProviderValue();
  return (
    <ApolloProvider client={apolloClient}>
      <AppProvider value={providerValue}>
        <FeatureFlagsProvider options={featureFlags}>
          <GoogleProvider>
            <Routes />
            <MobileAppUpdateModal />
          </GoogleProvider>
        </FeatureFlagsProvider>
      </AppProvider>
    </ApolloProvider>
  );
};

export default App;
