import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import mergeLabelsAttributes from "web/utils/page/product/universal/mergeLabelsAttributes";

import { IAttribute } from "web/types/Attributes";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

interface IWithLabelProps {
  ids: (number | string)[];
  code: string;
}

const withLabel = <Props extends object>(
  WrappedComponent: React.ComponentType<Props>
): FC<IWithLabelProps> => {
  const WithLabel: FC<IWithLabelProps> = ({ ids, code, ...restProps }) => {
    const { data, error, loading } = useDataCachedAttributes({
      ids: ["product_label"],
    }) as { data: IAttribute[]; error: unknown; loading: boolean };

    if (error) {
      return <ErrorComponent />;
    }
    if (loading) {
      return <Loading />;
    }

    if (data && data.length) {
      const attribute = isArrayHasItems(data) ? data[0] : ({} as IAttribute);
      const attributeOptions =
        attribute && attribute.attribute_options
          ? attribute.attribute_options
          : [];
      const labels = mergeLabelsAttributes(attributeOptions, ids);
      const currentLabelData = isArrayHasItems(labels)
        ? labels.find((label) => label && label.code === code)
        : null;

      return currentLabelData && currentLabelData.label ? (
        <WrappedComponent
          {...(restProps as Props)}
          label={currentLabelData.label}
        />
      ) : null;
    }

    return null;
  };

  return WithLabel;
};

export default withLabel;
