import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatHours from "web/utils/data/parser/dateAndTime/formatHours";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import classes from "./bankResetAnnouncement.scss";

interface IBanksResets {
  resetTime: string;
  points_bank_id: string;
  account_id: string;
  points_bank_name: string;
  balance: string;
  blockade_locked: number;
  blockade_time_blockade_end: string;
}

const BankResetAnnouncement = ({
  shouldShowMessage,
}: {
  shouldShowMessage: boolean;
}) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { itemsReset, itemsActive } = dataBanks || {};

  const banksReset: IBanksResets[] =
    (itemsActive
      ?.map((item) => {
        const itemFromResets = itemsReset?.find(
          (el) => el.points_bank_id === item.points_bank_id
        );
        return itemFromResets &&
          item.points_bank_id === itemFromResets.points_bank_id
          ? {
              ...item,
              resetTime: itemFromResets.reset_datetime,
            }
          : null;
      })
      .filter(Boolean) as IBanksResets[]) || [];

  const checkIfDatesEqual = (banks: IBanksResets[]) => {
    const dates = banks.map((item) => item.resetTime);
    return dates.every((v) => v === dates[0]);
  };

  switch (true) {
    case isArrayHasItems(banksReset) &&
      checkIfDatesEqual(banksReset) &&
      shouldShowMessage:
      return (
        <div className={classes.container}>
          <div className={classes.containerInner}>
            <p className={classes.topParagraph}>
              <strong>{__("UWAGA!")}</strong>{" "}
              <span>{__("Wkrótce Twoje punkty stracą ważność!")}</span>
              {banksReset.map((item, i, arr) => (
                <span key={item.points_bank_id}>
                  <strong> {item.balance} </strong>
                  <span>{__("pkt z banku punktów")} </span>
                  <span> {item.points_bank_name}</span>
                  {i + 1 === arr.length ? " " : ","}
                </span>
              ))}
              <span>{__("możesz wykorzystać do ")} </span>{" "}
              <strong>{formatDate(banksReset[0].resetTime)}</strong>
            </p>
            <p className={classes.secondParagraph}>
              <strong>{__("PAMIĘTAJ!")}</strong>
              <span> {__("Punkty są ważne do godziny")} </span>
              <strong>{formatHours(banksReset[0].resetTime)}.</strong>
            </p>
            <p className={classes.bottomParagraph}>
              {__(
                "Masz niewystarczającą ilość punktów na zakupy? Różnicę możesz dopłacić online!"
              )}
            </p>
          </div>
        </div>
      );
    case isArrayHasItems(banksReset) &&
      !checkIfDatesEqual(banksReset) &&
      shouldShowMessage:
      return (
        <div className={classes.container}>
          <div className={classes.containerInner}>
            <p className={classes.topParagraph}>
              <strong>{__("UWAGA!")}</strong>
              <span> {__("Wkrótce Twoje punkty stracą ważność!")} </span>
              {banksReset.map((item, i, arr) => (
                <span key={item.points_bank_id}>
                  <strong> {item.balance}</strong>
                  <span> {__("pkt z banku punktów")} </span>
                  <span>{item.points_bank_name}</span>
                  <span> {__(" możesz wykorzystać do godziny ")} </span>
                  <strong>
                    {formatHours(item.resetTime)} {formatDate(item.resetTime)}
                  </strong>
                  {i + 1 === arr.length ? "." : ","}
                </span>
              ))}
            </p>
            <p className={classes.bottomParagraph}>
              {__(
                "Masz niewystarczającą ilość punktów na zakupy? Różnicę możesz dopłacić online!"
              )}
            </p>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default BankResetAnnouncement;
