import { useMemo } from "react";

import useIsMobile from "web/hooks/useIsMobile";
import useIsPrint from "web/hooks/useIsPrint";
import useModal from "web/hooks/useModal";

import useAppMode from "../useAppMode";

const useProviderValue = () => {
  const {
    component,
    modalRef,
    menuIndex,
    dispatch,
    classes,
    withoutBackdrop,
    openModal,
    closeModal,
  } = useModal();
  const { print, isPrint } = useIsPrint();
  const isMobile = useIsMobile();
  const appMode = useAppMode();

  return useMemo(() => {
    return {
      modal: {
        component,
        modalRef,
        menuIndex,
        dispatch,
        classes,
        withoutBackdrop,
        openModal,
        closeModal,
      },
      isMobile,
      storeConfig: {},
      print,
      isPrint,
      appMode,
    };
  }, [
    component,
    menuIndex,
    dispatch,
    withoutBackdrop,
    isMobile,
    isPrint,
    appMode,
  ]);
};

export default useProviderValue;
