import { Button } from "@benefit-systems/common-components";

import { ImageSource } from "web/Layout/Common/Image";

import workInProgressAstronaut from "web/assets/images/workInProgressAstronaut.png";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import __ from "../Translations";
import classes from "./workInProgress.scss";

const WorkInProgress = () => {
  const { isMobile } = useAppContext();

  const info = isMobile
    ? "Strona w przygotowaniu"
    : "Strona jest obecnie w przygotowaniu";

  return (
    <div className={classes.root}>
      <ImageSource src={workInProgressAstronaut} />
      <h1 className={classes.title}>{__(info)}</h1>
      <span className={classes.info}>{__("Zapraszamy wkrótce")}</span>
      <Button className={classes.btn} variant="tertiary" to={urls.home}>
        {__("Przejdź do strony głównej")}
      </Button>
    </div>
  );
};

export default WorkInProgress;
