/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import { SkeletonProductsList } from "web/Layout/Common/SkeletonComponents";

import usePrevious from "web/hooks/usePrevious/usePrevious";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getProductTypeFilter from "web/utils/page/category/getProductTypeFilter";

import { pageDefault, pageName } from "web/constants/toolbar";

import { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { Nullable } from "web/types/Utils";

import useDataCachedProductsDomesticTourism from "web/features/useDataCached/useDataCachedProductsDomesticTourism";
import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import Products from "./products";
import AnixeTourismProductsGtms from "./anixeTourismProductsGtms";
import ProductsGtms from "./productsGtms";

interface IAbstractListingContainerProps {
  ids: number[];
  token: string;
  storeId: number;
  count: number;
  currency: string;
  categoryId: Nullable<string | number>;
  campaignCounts: number;
  campaign: false | ICampaignGraphics;
  categoriesPath: string;
  cgGroup1Path?: string;
  pageSizeDefault: number;
}

interface IContainerWithProductsProps extends IAbstractListingContainerProps {
  activeFilters: string[];
}

const ContainerWithProducts: FC<IContainerWithProductsProps> = memo(
  ({
    ids = [],
    count,
    currency = "",
    campaign = false,
    campaignCounts,
    categoryId = null,
    activeFilters,
    categoriesPath = "",
    cgGroup1Path = "",
    pageSizeDefault,
  }) => {
    const { data, error, loading } = useDataCachedProductsInList({
      ids,
    });

    const previousData = usePrevious(data || []);
    const previousIds = usePrevious(ids);
    const [newProductsId, setNewProductsId] = useState<number[]>([]);

    const previousValue = useMemo(() => {
      return previousData?.length || 0;
    }, [previousIds]);

    useEffect(() => {
      setNewProductsId(
        ids?.filter((productId) => !previousIds?.includes(productId))
      );
    }, [ids]);

    switch (true) {
      case !!loading && (!data || !Object.keys(data).length): {
        return <SkeletonProductsList />;
      }
      case !!error: {
        console.error(error);
        return <ErrorComponentWithContainer />;
      }
      default: {
        return (
          <>
            {data && (
              <Products
                products={data}
                count={count}
                activeFilters={activeFilters}
                campaign={campaign}
                campaignCounts={campaignCounts}
                categoryId={categoryId!}
                newProductsId={newProductsId}
              />
            )}
            {loading && <SkeletonProductsList />}
            <ProductsGtms
              data={data}
              previousValue={previousValue}
              currency={currency}
              categoriesPath={categoriesPath}
              cgGroup1Path={cgGroup1Path}
              pageSizeDefault={pageSizeDefault}
            />
          </>
        );
      }
    }
  }
);

type ContainerWithAnixeTourismProductsProps = Omit<
  IAbstractListingContainerProps,
  "token" | "storeId"
> & {
  additionalParameters: string;
};

const ContainerWithAnixeTourismProducts: FC<ContainerWithAnixeTourismProductsProps> =
  memo(
    ({
      ids = [],
      count,
      currency = "",
      campaign = null,
      campaignCounts,
      categoryId = null,
      additionalParameters = null,
      categoriesPath = "",
      cgGroup1Path = "",
      pageSizeDefault,
    }) => {
      const previousIds = usePrevious(ids);
      const [newProductsId, setNewProductsId] = useState<number[]>([]);

      const { search } = useLocation();

      const mbProductTypeFilter = useMemo(
        () => getProductTypeFilter(search),
        [search]
      );

      const { error, loading, data } = useDataCachedProductsDomesticTourism({
        ids,
        ...(additionalParameters ? { additionalParameters } : {}),
        ...(mbProductTypeFilter
          ? {
              additionalOptions: {
                attributes: [mbProductTypeFilter],
              },
            }
          : {}),
      });

      const previousValue = usePrevious(data?.length || 0) as number;

      useEffect(() => {
        setNewProductsId(
          ids?.filter((productId) => !previousIds?.includes(productId))
        );
      }, [ids]);

      switch (true) {
        case !!loading && (!data || !Object.keys(data).length): {
          return <SkeletonProductsList />;
        }
        case !!error: {
          console.error(error);
          return <ErrorComponentWithContainer />;
        }
        default: {
          return (
            <>
              {data && (
                <Products
                  products={data}
                  count={count}
                  campaign={campaign!}
                  campaignCounts={campaignCounts}
                  categoryId={categoryId!}
                  newProductsId={newProductsId}
                  isAnixeTourism
                />
              )}
              {loading && <SkeletonProductsList />}
              <AnixeTourismProductsGtms
                data={data}
                ids={ids}
                additionalParameters={additionalParameters}
                previousValue={previousValue}
                currency={currency}
                categoriesPath={categoriesPath}
                cgGroup1Path={cgGroup1Path}
                pageSizeDefault={pageSizeDefault}
              />
            </>
          );
        }
      }
    }
  );

interface IListingProductsContainerProps {
  ids: number[];
  categoryId?: string | number;
  campaign?: false | ICampaignGraphics;
  categoriesPath?: string;
  cgGroup1Path?: string;
  isAnixeTourism?: boolean;
  additionalParameters: string;
  activeFilters?: string[];
}

const ListingProductsContainer: FC<IListingProductsContainerProps> = ({
  ids = [],
  campaign = false,
  categoryId = null,
  isAnixeTourism = false,
  additionalParameters = "",
  activeFilters,
  categoriesPath = "",
  cgGroup1Path = "",
}) => {
  const { search } = useLocation();
  const paramsSearch = new URLSearchParams(search);
  const {
    token,
    id: storeId,
    grid_per_page: pageSizeDefault,
    base_currency_code: currency,
  } = useSelector((state) => state.app.storeConfig);
  const pageNameFromParams = paramsSearch.get(pageName);
  const pageCurrent =
    (pageNameFromParams && +pageNameFromParams) || pageDefault;
  const count = ids?.length;
  const idsCountToShowDefault = Math.abs(pageCurrent * pageSizeDefault);

  const campaignCounts = useMemo(() => {
    if (campaign && campaign.listing_type && campaign.on_listing) {
      if (+campaign.listing_type === 1) {
        const circleIndex = parseInt(campaign.on_listing, 10);
        const adsPerPage = idsCountToShowDefault / circleIndex;
        return idsCountToShowDefault >= circleIndex
          ? Math.floor(adsPerPage)
          : 0;
      }
      const campaignIndexes = campaign.on_listing.split(",");
      const campaignIndexesFiltered = isArrayHasItems(campaignIndexes)
        ? campaignIndexes.filter((campaignIdx) => parseInt(campaignIdx, 10))
        : [];
      return isArrayHasItems(campaignIndexesFiltered)
        ? campaignIndexesFiltered
            // @ts-ignore
            .sort((a, b) => a - b)
            .reduce((result, current) => {
              // @ts-ignore
              if (idsCountToShowDefault >= current) {
                return result + 1;
              }
              return result;
            }, 0)
        : 0;
    }

    return 0;
  }, [JSON.stringify(campaign), idsCountToShowDefault, count, pageCurrent]);

  const idsCountToShow = idsCountToShowDefault - campaignCounts;
  const idsSliced = useMemo(() => {
    return isArrayHasItems(ids)
      ? ids
          .slice(0, idsCountToShow)
          // @ts-ignore
          .reduce((result, id) => (id && +id ? [...result, +id] : result), [])
      : [];
  }, [idsCountToShow, JSON.stringify(ids)]);

  return (
    <>
      {isAnixeTourism ? (
        <ContainerWithAnixeTourismProducts
          ids={idsSliced as number[]}
          count={count}
          currency={currency}
          campaign={campaign}
          campaignCounts={campaignCounts}
          categoryId={categoryId}
          additionalParameters={additionalParameters}
          categoriesPath={categoriesPath}
          cgGroup1Path={cgGroup1Path}
          pageSizeDefault={pageSizeDefault}
        />
      ) : (
        <ContainerWithProducts
          ids={idsSliced as number[]}
          count={count}
          activeFilters={activeFilters as string[]}
          currency={currency}
          token={token}
          storeId={storeId}
          campaign={campaign}
          campaignCounts={campaignCounts}
          categoryId={categoryId}
          categoriesPath={categoriesPath}
          cgGroup1Path={cgGroup1Path}
          pageSizeDefault={pageSizeDefault}
        />
      )}
    </>
  );
};

export default ListingProductsContainer;
