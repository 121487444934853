/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import stringNumToFixed from "web/utils/data/transform/stringNumToFixed";

import CANCELLATION_POLICY from "web/constants/cancellationPolicy";
import restUrls from "web/constants/restUrls";

import {
  IDomesticTourismHotelRatesOffer,
  IDomesticTourismMinPrice,
  IRoom,
} from "web/types/Tourism";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";

export const useTourismCheckoutInfo = (
  offer: IDomesticTourismHotelRatesOffer,
  minPrice: Nullable<number>
) => {
  const warningCopy = __(
    "Brak płatności w podanych kwotach i terminach będzie skutkował anulacją kosztową rezerwacji zgodnie z warunkami podanymi powyżej."
  );

  return useMemo(() => {
    const {
      cnxs: cnxsBeforeMinPrice,
      rooms,
      total_price,
      source,
      cnxs_min_price: cnxsMinPrice,
    } = offer || {};

    const cnxs = cnxsMinPrice || cnxsBeforeMinPrice;

    if (!cnxs || !rooms || !total_price) {
      return null;
    }
    const isGDS = source !== "MYBENEFIT";
    const cancellationPolicy = rooms[0]?.cnx && rooms[0].cnx;
    const isRefundable =
      cancellationPolicy !== CANCELLATION_POLICY.NON_REFUNDABLE ||
      (isGDS && cancellationPolicy === CANCELLATION_POLICY.UNKNOWN);

    if (!isRefundable) {
      return [
        `${__("Wymagamy od Ciebie płatności w kwocie")} ${stringNumToFixed(
          total_price
        )} ${__("zł")}.`,
      ];
    }

    const hasDates = Boolean(cnxs[0]?.from && cnxs[0].to);

    if (hasDates) {
      return [
        ...cnxs.reduce(
          (acc, { from, value, from_time }, index, array) => {
            const dateTo = dayjs(`${from} ${from_time}`).subtract(1, "second");
            const paymentTo = dateTo.format("HH:mm");
            const toFormatted = dateTo.format("DD.MM.YYYY");

            if (array.length === 0 || index === array.length - 1) {
              // Last payment
              acc.push(
                `${__("do")} ${toFormatted}, ${__("godz.")} ${paymentTo} – ${__(
                  "dopłać brakującą kwotę do pełnej wartości rezerwacji, która wynosi"
                )} <b>${stringNumToFixed(total_price)} ${__("zł")}</b>`
              );
            } else {
              // Payments between first and last
              acc.push(
                `${__("do")} ${toFormatted}, ${__("godz.")} ${paymentTo} – ${__(
                  "dopłać do minimum"
                )} <b>${stringNumToFixed(value)} ${__("zł")}</b>, ${__(
                  "lub do pełnej wartości rezerwacji"
                )}`
              );
            }

            return acc;
          },
          [__("Opłać swój pobyt w wyznaczonych terminach:"), warningCopy]
        ),
      ];
    }

    return (
      minPrice && [
        `${__("Wymagamy od Ciebie płatności w kwocie")} ${stringNumToFixed(
          minPrice
        )} ${__("zł")}.`,
      ]
    );
  }, [offer, minPrice]);
};

export const useTourismCheckoutInfoWithRedux = () => {
  const { offer, amountMinimal } = useSelector(
    ({ tourismQuoteDomestic: { percentage, quote, amountMinimal } }) => ({
      minPercentage: percentage,
      offer: quote?.offer,
      amountMinimal,
    })
  );

  const checkoutInfo = useTourismCheckoutInfo(offer!, amountMinimal);

  return checkoutInfo;
};

export const useTourismCheckoutInfoWithMinPriceFetch = (
  offer: IDomesticTourismHotelRatesOffer & { room: IRoom[] }
) => {
  const [minPrice, setMinPrice] = useState<Nullable<number>>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchMinPrice = async () => {
      const { price } = (await request(restUrls.minPriceAnixe, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify({
          form: {
            supplier_code: offer.source,
            cnx_type: offer.room[0].cnx_type,
            total_price: offer.total_price,
            cnxs: offer.cnxs,
          },
        }),
      })) as IDomesticTourismMinPrice;
      if (isMounted) {
        setMinPrice(+price);
      }
    };
    fetchMinPrice();
    return () => {
      isMounted = false;
    };
  }, []);

  const checkoutInfo = useTourismCheckoutInfo(
    { ...offer, rooms: offer.room },
    minPrice
  );

  return checkoutInfo;
};
