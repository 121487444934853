import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatHours from "web/utils/data/parser/dateAndTime/formatHours";

import { Nullable } from "web/types/Utils";

import {
  OutcomingWebViewMessageType,
  sendMessageToNativeApp,
} from "web/context/webView";

type PrintCodeDirectlyArgs = {
  productName: string;
  code?: Nullable<string>;
};

type PrintCodeDirectlyFn = (args: PrintCodeDirectlyArgs) => void;

const printCodeDirectly: PrintCodeDirectlyFn = ({ productName, code }) => {
  if (!window.ReactNativeWebView) {
    window.print();
    return;
  }
  const now = Date.now();
  const html = `
        <div style="display:flex;flex-direction:column;">
            <div style="font-size:10px;display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:32px">
                <div>${formatDate(now)}, ${formatHours(now)}</div>
                <div>${window.location}</div>
            </div>
            <div style="text-align:center;">
                <h1 style="font-size:16px;">
                ${__("Twój kod")}
                </h1>
                <h2 style="font-size:20px;">
                ${productName}
                </h1>
                <p style="font-size:14px;">
                ${code}
                </p>
            </div>
        </div>
    `;

  sendMessageToNativeApp({
    type: OutcomingWebViewMessageType.CONVERT_TO_PDF,
    payload: {
      html,
      pdfFileName: __("Moje zamówienia"),
    },
  });
};

export default printCodeDirectly;
