export enum RestAPIS {
  SUB_MANAGEMENT_API = "/api/subscription-management/v1/rest",
}

export default {
  customer: "/rest/V1/feniks/customers/me",
  changePassword:
    "/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/update-password",
  customerPassword: "/rest/V1/customers/me/password",
  customerReviews: "/rest/V1/customers/me/reviews",
  customerReviewsAdd: "/rest/V1/customers/me/reviews/add",
  customerOrderEditProductOptions:
    "/rest/V1/customers/me/orders/items/set-custom-options",
  canCustomerSetCustomOptions:
    "/rest/V1/customers/me/orders/items/can-set-custom-options",
  getProductDataByItemOrderId: "/rest/V1/customers/me/orders/items",
  messagesGet: "/api/notification/v1/rest/messages/get-for-me",
  messagesUnread: "/api/notification/v1/rest/messages/get-unread-for-me",
  messageDelete:
    "/api/notification/v1/rest/messages/delete?messagesIds[]={messageId}",
  messageMarkAsRead:
    "/api/notification/v1/rest/messages/mark-as-read?messagesIds[]={messageId}",
  remaindersGetList: "/api/notification/v1/rest/messages/get-reminders",
  remainderDelete:
    "/api/notification/v1/rest/messages/delete-reminder?messagesIds[]={reminderId}",
  remainderCreate: "/api/notification/v1/rest/messages/create-reminder",
  catalogManagement: "api/catalog-management/v1",
  codeSettings: "/api/catalog-management/v1/rest/code-settings?productId={sku}",
  supplierConfig: "/api/catalog-management/v1/rest/supplier-configurations",
  wishlist: "/rest/V1/customers/me/wishlist",
  wishlistCount: "/rest/V1/customers/me/wishlistCount",
  getGiftPdf: "/rest/V1/feniks/orders/item/bar-code",
  wishlistAddItem: "/rest/V1/customers/me/wishlist/add",
  wishlistRemoveItem: "/rest/V1/customers/me/wishlist/remove/{itemId}",
  wishlistShare: "/rest/V1/customers/me/wishlist/share",
  customerCodes: "/rest/V1/customers/me/orders/codes",
  subscriptionUserConfiguration: `${RestAPIS.SUB_MANAGEMENT_API}/subscription-user-configuration`,
  customerCodesMark: "/rest/V1/customers/me/orders/codes/mark/:itemId",
  orders: "/rest/V1/customers/me/orders",
  customerOrderDetails: "/rest/V1/customers/me/orders/:orderId",
  pointsBank: "/rest/V1/customers/me/pointsBank",
  resetPointsBank: "/rest/V1/customers/me/pointsBank/resets",
  surcharge: "/rest/V1/customers/me/surcharge",
  buyNow: "/rest/V1/feniks/buy-now/:sku",
  buyNowDetails: "/rest/V1/feniks/carts/mine/:cartId",
  cart: "/rest/V1/feniks/carts/mine",
  cartAddItem: "/rest/V1/feniks/carts/mine/items",
  cartRemoveItem: "/rest/V1/feniks/carts/mine/items/remove/:itemId",
  cartUpdateItem: "/rest/V1/feniks/carts/mine/items/:itemId",
  cartUpdateItemWithForm: "/rest/V1/feniks/carts/mine/set-custom-options",
  cartItemsPointsBank: "/rest/V1/feniks/carts/mine/items/pointsBank",
  cartSelectPaymentMethod: "/rest/V1/feniks/carts/mine/selected-payment-method",
  placeOrder: "/rest/V1/feniks/carts/mine/payment-information",
  placeOrderTourismInternational:
    "/rest/V1/feniks/tourism/international/mine/order/place",
  placeOrderTourismDomestic: "/rest/V1/feniks/tourism/anixe/mine/order/place",
  payments: "/rest/V1/feniks/payments",
  successOrderDetails: "/rest/V1/feniks/order-detail/:orderId",
  cartAddPromoCode: "/rest/V1/feniks/carts/mine/coupons/:couponCode",
  cartDeletePromoCode: "/rest/V1/feniks/carts/mine/coupons",
  employeeDetails: "/api/employee-management/v1/rest/employee/me",
  employeeRentableGroups:
    "/api/employee-management/v1/rest/employee/me-rg-windows",
  employeeRentableGroupsAdditionalData:
    "/api/employee-management/v1/rest/me-rg-additional-data",
  employeeRentableGroupsAdditionalDataUpdate:
    "/api/employee-management/v1/rest/me-create-rg-additional-data",
  employeeUpdateDetails: "/api/employee-management/v1/rest/employee/update/me",
  historyCharges: "/api/employee-management/v1/rest/history-charges",
  employeeConfiguration: "/api/configuration/v1/rest/get-my-config",
  availableUserBenefits: `${RestAPIS.SUB_MANAGEMENT_API}/available-user-benefits`,
  availableUserBenefitsGrouped: `${RestAPIS.SUB_MANAGEMENT_API}/available-user-benefits-grouped`,
  currentUserSubscription: `${RestAPIS.SUB_MANAGEMENT_API}/current-user-subscription`,
  currentUserSubscriptionAgreements: `${RestAPIS.SUB_MANAGEMENT_API}/current-user-subscription/agreements`,
  subscriptions: `${RestAPIS.SUB_MANAGEMENT_API}/subscriptions`,
  activeForms: `${RestAPIS.SUB_MANAGEMENT_API}/active-forms`,
  subscriptionPdfForm: `${RestAPIS.SUB_MANAGEMENT_API}/current-user-subscription/pdf-form`,
  employeeSubscriptionItems: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-items`,
  employeeSubscriptionDraft: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/benefits`,
  employeeSubscriptionDraftForms: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/forms`,
  employeeSubscriptionDraftReceiverForms: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/add-receiver-forms`,
  employeeSubscriptionDraftRemoveReceivers: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/remove-receivers`,
  employeeSubscriptionDraftAgreements: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/agreements`,
  employeeSubscriptionDraftAgreementsOffline: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/offline-agreements`,
  employeeSubscriptionDraftSubmit: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft/:uuid/submit`,
  employeeSubscriptionDraftItemsEditActiveForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft-items/:uuid/edit-active-form`,
  employeeSubscriptionDraftItemsEditForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft-items/:uuid/edit-form`,
  employeeSubscriptionItemsEditForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-items/:uuid/edit-form`,
  employeeSubscriptionItemsEditActiveForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-items/:uuid/edit-active-form`,
  employeeSubscriptionDraftItemsEditReceiverForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-draft-items/:uuid/edit-receiver-form`,
  employeeSubscriptionItemsEditReceiverForm: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-items/:uuid/edit-receiver-form`,
  employeeGroupPrices: `${RestAPIS.SUB_MANAGEMENT_API}/benefit-employee-group-prices`,
  duplicateCardConfiguration: `${RestAPIS.SUB_MANAGEMENT_API}/order-duplicate-sport-card-configurations`,
  duplicateCardOrder: `${RestAPIS.SUB_MANAGEMENT_API}/employee-subscription-item-receivers/:uuid/order-card`,
  employeeGroups: `${RestAPIS.SUB_MANAGEMENT_API}/benefit-employee-groups`,
  benefitsAttachments: `${RestAPIS.SUB_MANAGEMENT_API}/benefits/:uuid/attachments/:aid`,
  filterBenefitGroupsEmployee: `${RestAPIS.SUB_MANAGEMENT_API}/benefit-groups/filter-benefit-groups-for-employee`,
  getAgreements: "/api/agreement/v1/rest/get-my-agreements",
  myAgreements: "/api/agreement/v1/rest/my-agreements",
  companyDetails: "/api/company-management/v1/rest/company/mine",
  interfaceTranslations: "/api/translator/v1/rest/get-by-scope",
  cmsTranslations: "/api/translator/v1/rest/cms/get-by-code",
  interfaceMissingTranslations: "/api/translator/v1/rest/get-by-phrase",
  externalActiveForms: `${RestAPIS.SUB_MANAGEMENT_API}/external-active-forms`,
  hotelRatesAnixe: "/rest/V1/feniks/tourism/anixe/hotelRates",
  minPriceAnixe: "/rest/V2/feniks/tourism/anixe/cnx/min/price",
  productLimit: "/rest/V1/feniks/customers/limit",
  helpCenterOpinion: "/rest/V1/helpcenter/articleopinion",
  helpCenterAddService: "/rest/V1/helpceneter/addservice",
  helpCenterIndividual: "/rest/V1/helpceneter/individual",
  catalogProductMediaPrefix: "/api/catalog-management/v1/",
  barCodeImage: "/rest/V1/feniks/orders/item/bar-code-image",
  productCode: "/rest/V1/feniks/orders/item/product-code",
  isProductActive: "/rest/V1/feniks/product/is-active/",
  checkoutConfiguration: "/checkout/subscription/configuration",
  stockQty: "/rest/V1/feniks/products/attributes/getStockQty/:productId",

  // MFAAuthentication
  MFAConfiguration:
    "/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/mfa/configured",
  MFAReset:
    "/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/mfa/reset",
  // Gateway PrepaidCards URLS
  prepaidCards: "/api/gateway/prepaid-cards",
  prepaidCardsOrders: "/api/gateway/prepaid-cards/checkout/orders",
};
